import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import _ from 'underscore'
import moment from "../moment-tz"
import * as firebase from 'firebase';

import * as actions from '../actions/index'

import { dateFormat } from '../Helper';
import Round from '../models/Round';
import Booking from '../models/Booking';
import ImageUploadInput from './inputs/ImageUploadInput';
import { CloudImage } from './CloudImage';

class PaymentScreen extends Component {
    constructor() {
        super()
        this.state = { rounds: [], loading: true, booking: {} }

    }
    async componentDidMount() {
        let booking = await Booking.get(this.props.match.params.booking_id)
        if (booking.exists) {
            booking = booking
            this.setState({ booking, loading: false })
        }

        debugger
    }
    async save(date) {
        let { booking, selectedImageFile } = this.state
        let lineUserId = this.props.line_user

        await this.setState({ loading: true })
        if (booking) {

            if (selectedImageFile && selectedImageFile.name) {
                let ext = selectedImageFile.name.split('.')[1]
                let storageRef = firebase.storage().ref();
                let image = `payments/${booking.id}.${ext}`
                let fileRef = storageRef.child(image);
                await fileRef.put(selectedImageFile)
                await booking.ref.update({ image })
            }
            window.liff.closeWindow();
            this.setState({ loading: false })
            alert("ข้อมูลได้ถูกส่งให้ทางวัดเรียบร้อยแล้ว")
        }
    }
    render() {
        let { loading, selectedImageData, selectedImageFile, booking } = this.state
        if (!booking || !booking.exists) {
            return "ไม่พบข้อมูลในส่วนนี้"
        }
        else {
            let bookingData = booking.data()
            let { donatorName, donatorPhone, donateQuantity, donateType } = bookingData

            return <div className="container m20">
                {loading && <div className="loading">กำลังส่งข้อมูล</div>}
                <h1 className="title mt20 is-5">แจ้งการโอนเงินบริจาค</h1>
                <div className="donator-name">วันที่ถวายเพล: {dateFormat(bookingData.date)}</div>
                <div className="donator-name">ชื่อผู้บริจาค: {bookingData.donatorName}</div>
                <div className="donator-phone">โทร: {bookingData.donatorPhone}</div>
                <div className="donate-quantity">จำนวน {bookingData.donateQuantity} รูป</div>
                <div className="donate-type">{bookingData.donateType}</div>
                {bookingData.image && <CloudImage src={bookingData.image} />}
                <div class="upload-btn-wrapper">
                    <button class="button is-info is-fullwidth">{!selectedImageData ? 'เลือกภาพหลักฐานการโอนเงิน' : 'เลือกภาพหลักฐานการโอนเงินใหม่'}</button>
                    <ImageUploadInput onFileSelect={selectedImageFile => this.setState({ selectedImageFile })} onBase64Data={selectedImageData => this.setState({ selectedImageData })} />
                </div>
                <button className="button is-fullwidth is-success" onClick={() => this.save()} disabled={!selectedImageFile}>ส่งข้อมูล</button>
            </div>;
        }
    }
}

const mapStateToProps = ({ current_user, line_user }) => ({ current_user, line_user });

export default withRouter(connect(mapStateToProps, actions)(PaymentScreen))