import * as firebase from 'firebase';

const config = {
    apiKey: "AIzaSyBfY_l-DLqVm7t_2CZuG0DAecCpWu3T5kg",
    authDomain: "foremonk9.firebaseapp.com",
    databaseURL: "https://foremonk9.firebaseio.com/",
    projectId: "foremonk9",
    storageBucket: "gs://foremonk9.appspot.com",
    // messagingSenderId: "ENTER YOURS HERE"
}

console.log("Initialize firebase")
firebase.initializeApp(config);
// firebase.firestore().enablePersistence().catch(function (err) {
//     console.log("Offline mode is not working")
//     if (err.code === 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//     } else if (err.code === 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//     }
// });
