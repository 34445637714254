import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import * as firebase from 'firebase';
import * as firebaseui from 'firebaseui';

import * as actions from '../actions/index'
import { bindActionCreators } from 'redux';

var db = firebase.firestore();

class Header extends Component {
  constructor() {
    super()
    this.signIn = this.signIn.bind(this)
  }

  componentDidMount() {
    // Setup toggle nav
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }
  }

  componentDidUpdate() {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        let hasClass = false;
        for (let c in el.classList) {
          if (el.classList[c] === "sc") {
            hasClass = true;
            break;
          }
        }

        if (!hasClass) {
          el.classList.add("sc");

          el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

          });
        }
      });
    }
  }

  signIn() {

    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', {
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      signInSuccessUrl: window.location.href
    }
    );
  }
  signOut() {
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
    }).catch(function (error) {
      // An error happened.
    });
  }

  render() {

    return <div>

      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="https://www.hitevent.com/assets/hitevent_logo-055af526c0166a06dd2153eb4adbd2d98e79ed7f7dc844e3f75987f3659da9d6.png" width="25" height="25" />
          </a>
        </div>
      </nav>
      <div>
      </div>
    </div>


  }
}

const mapStateToProps = ({ current_user, user_roles }) => ({ current_user, user_roles });

export default withRouter(connect(mapStateToProps, actions)(Header))