import moment from "./moment-tz"

export function dateFormat(date, hours) {
    if (!date) {
        return ''
    }
    try {
        if (date.constructor.name === 'Timestamp' || date.toDate) {
            date = date.toDate()
        }
        var mdate = moment(date);
        if (hours) {
            mdate.set({ hours })
        }
        if (hours === false || hours === null)
            return mdate.format('ddd D MMM Y')
        else
            return mdate.format('ddd D MMM Y HH:mm น.')
    }
    catch (e) {
        return ''
    }
}

export function minimalDateFormat(date, hours) {
    if (date.constructor.name === 'Timestamp' || date.toDate) {
        date = date.toDate()
    }
    var mdate = moment(date);
    if (hours) {
        mdate.set({ hours })
    }
    return mdate.fromNow()
}

export function bookingStatusText(sid) {
    switch (sid) {
        case 'incomplete': return 'รายการยังไม่สมบูรณ์';
        case 'pending': return 'กำลังหาติวเตอร์';
        case 'submitted': return 'กำลังหาติวเตอร์';
        case 'matched': return 'นัดติวเตอร์แล้ว';
        case 'complete': return 'พบนติวเตอร์แล้ว';
        case 'failed': return 'ไม่พบติวเตอร์';
        case 'cancel': return 'ยกเลิก';
        case 'waiting_payment': return 'รอการชำระเงิน';
    }
}

export function levelText(level) {
    if (level <= 6) {
        return `ประถมศึกษาปีที่ ${level}`
    }
    else {
        return `มัธยมศึกษาปีที่ ${level - 6}`
    }
}


export function levelRangeText(level) {
    switch (level) {
        case 0: return 'นอกหลักสูตร';
        case 6: return 'ประถมศึกษา';
        case 9: return 'มัธยมศึกษาตอนต้น';
        case 12: return 'มัธยมศึกษาตอนปลาย';
    }
}

export function studentLevelList() {
    let data = {}

    for (var i = 1; i <= 6; i++) {
        data[i] = `ประถมศึกษาชั้นปีที่ ${i}`
    }

    for (var i = 7; i <= 12; i++) {
        data[i] = `มัธยมศึกษาชั้นปีที่ ${i - 6}`
    }
    return data
}