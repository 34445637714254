import React, { Component } from 'react';

class ImageUploadInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            fileUrl: null
        }
        this.handleChange = this.handleChange.bind(this)
    }
    async handleChange(event) {
        let file = event.target.files[0]
        let fileUrl = URL.createObjectURL(file)

        this.props.onChange && this.props.onChange(event)
        if (this.props.onFileSelect) {
            this.props.onFileSelect(file)
        }
        if (this.props.onBase64Data) {
            let base64Data = await this.toBase64(file)
            this.setState({ file, fileUrl, base64Data })
        }
        else {
            this.setState({ file, fileUrl })
        }
    }

    toBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
    }
    render() {
        return (
            <div>
                <input type="file" onChange={this.handleChange} />
                <img src={this.state.fileUrl} />
            </div>
        );
    }
}

export default ImageUploadInput