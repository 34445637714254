import * as firebase from 'firebase';
import FirebaseModel from './FirebaseModel';
var db = firebase.firestore();

export default class Round extends FirebaseModel {

}

Round.collection = 'rounds_test';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();