import './firebaseInitialize'
import './extendFunctions'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import * as firebase from 'firebase';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducers'

import $ from "jquery";


const store = createStore(rootReducer)

const ProviderApp = () => (
    <Provider store={store}>
        <App />
    </Provider>
)

ReactDOM.render(<ProviderApp />, document.getElementById('root'))
serviceWorker.unregister();

// -  Firebase user function
$(document).ready(function () {
    firebase.auth().onAuthStateChanged(async function (user) {

        if (user) {
            let idTokenResult = await user.getIdTokenResult()
            user.admin = !!idTokenResult.claims.admin
            store.dispatch({ type: 'USER_ROLES_SET', payload: user.admin && ['admin'] })
            store.dispatch({ type: 'USER_SET', payload: user })
            // User is signed in.

        } else {
            store.dispatch({ type: 'USER_SET', payload: null })
            // No user is signed in.
        }
    });


})