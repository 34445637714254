
// -  Extend Function
// eslint-disable-next-line
Object.defineProperty(String.prototype, 'humanize', {
    get: function () {
        var frags = this.split('_');
        for (let i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
})

// eslint-disable-next-line
Object.defineProperty(String.prototype, 'capitalize', {
    get: function () {
        return this.charAt(0).toUpperCase() + this.slice(1).replace("_", " ")
    }
})